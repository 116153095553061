<template>
<v-row class="mt-xl-n16 negative-margin" align="center" justify="center">
  <v-col cols="11" class="col-lg-9">
    <v-card
      :loading="processLoading"
      class="rounded-xl"
    >
      <template slot="progress">
        <v-progress-linear
          color="secondary"
          height="10"
          indeterminate
        ></v-progress-linear>
      </template>
      <div class="px-md-16 mx-6 py-md-12 py-6">
        <transition mode="out-in">
          <router-view />
        </transition>
      </div>
    </v-card>
  </v-col>
</v-row>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "ContainerLayout",
   data: () => ({
      selection: 1,
    }),
  computed: {
    ...mapGetters(["processLoading"]),
  },
};
</script>
<style scoped lang="scss">

::ng-deep .mt-md-n16 {
  margin-top: -70px !important;
}
</style>